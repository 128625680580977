import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserDetails, IUserState, ILoginPayload } from 'types/user/IUser';
import { IAppState } from 'types/state/IAppState';

export const initialState: IUserState = {
  isLoading: false,
  userDetails: null
};

export const userSlice = createSlice({
  name: 'fnbUser',
  initialState,
  reducers: {
    authenticate: (state, { payload }: PayloadAction<ILoginPayload>) => {
      state.isLoading = true;
    },
    authenticateDone: (state, { payload }: PayloadAction<IUserDetails>) => {
      state.isLoading = false;
      state.userDetails = payload;
    },
    authenticateFail: (state, { payload }: PayloadAction) => {
      state.isLoading = false;
    },
    refreshToken: (state, { payload }: PayloadAction<string>) => {
      state.userDetails.access_token = payload;
    },
    logout: (state) => {
      localStorage.removeItem('fnbUserAccessToken');
      localStorage.removeItem('fnbUserRefreshToken');
      state.userDetails = null;
    }
  }
});

export const { authenticate, authenticateDone, authenticateFail, refreshToken, logout } =
  userSlice.actions;

export const userSelector = (state: IAppState): IUserState => state.fnbUser;

export default userSlice.reducer;
