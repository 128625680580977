import axios from 'axios';

import config, { axiosInstance } from './index';
import { logout, refreshToken } from 'store/user/slice';

const setup = () => {
  axiosInstance.interceptors.request.use(
    (con) => {
      const token = localStorage.getItem('fnbUserAccessToken');

      if (token) {
        con.headers['Authorization'] = 'Bearer ' + token;
      }

      return con;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== config.login && err.response) {
        // Access Token was expired
        if (
          (err.response.status === 401 || err.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            const rs = await axios.post(config.apiUrl + config.refresh, {
              refresh: localStorage.getItem('fnbUserRefreshToken')
            });
            const { access } = rs.data;
            localStorage.setItem('fnbUserAccessToken', access);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
