import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2F4CEB'
    },
    secondary: {
      main: '#FDD74F'
    },
    error: {
      main: '#FF2B51'
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: {
      fontSize: '2.25rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    h2: {
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    h3: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    h4: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    h5: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    h6: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 'bold',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    body1: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    body2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    subtitle1: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    },
    caption: {
      fontSize: '0.625rem',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '-0.006em',
      lineHeight: 'initial',
      '&.MuiTypography-gutterBottom': {
        marginBottom: '1em'
      }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          fontSize: '1rem',
          textTransform: 'initial'
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        text: {
          textTransform: 'initial'
        },
        sizeLarge: {
          textTransform: 'initial',
          padding: '8px 16px',
          fontWeight: 'bold'
        },
        sizeSmall: {
          fontSize: '0.875rem'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '4px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontWeight: 'bolder',
          padding: '4px 8px',
          textTransform: 'uppercase'
        },
        sizeSmall: {
          height: '1.25rem',
          background: '#ffffff'
        },
        labelSmall: {
          paddingLeft: '0',
          paddingRight: '0'
        },
        outlined: {
          borderRadius: '24px',
          padding: '16px 4px',
          borderColor: '#1E3440'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          marginBottom: 0
        },
        multiline: {
          padding: 0
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#A9AFB2'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          padding: '10.5px 16px'
        },
        multiline: {
          padding: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10.5px 16px'
        },
        multiline: {
          padding: 0
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0
        }
      }
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          maxWidth: '400px'
        },
        message: {
          padding: '10px 0 8px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#000000',
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#000000'
          }
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#BCBEC8',
          minWidth: '65px',
          padding: '0 0.25rem'
        },
        label: {
          color: '#000000',
          '&.Mui-selected': {
            color: '#2F4CEB'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          borderRadius: '0.5rem'
        },
        popupIndicator: {
          display: 'none'
        },
        clearIndicator: {
          visibility: 'visible',
          color: '#000000'
        },
        paper: {
          boxShadow: '0px 4px 16px rgba(23, 25, 35, 0.12)'
        },
        groupLabel: {
          color: '#696D81',
          lineHeight: '32px'
        },
        loading: {
          color: '#000000'
        }
      }
    }
  }
});

export default theme;
