import { css } from '@emotion/react';

export default css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth  */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
  }

  body {
    margin: 0;
    color: #000000;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  img,
  svg {
    display: block;
  }

  button:focus {
    outline: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .swiper-slide {
    width: auto;
  }

  iframe {
    border: none;
  }
`;
