import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUtilsState } from 'types/utils/IUtils';
import { IAppState } from 'types/state/IAppState';
import { IAirport } from 'types/content/airports';

export const initialState: IUtilsState = {
  currency: 'USD',
  adult: false,
  sidebarCollapsed: false,
  route: [],
  cookies: false
};

export const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    changeCurrency: (state, { payload }: PayloadAction<string>) => {
      state.currency = payload;
    },
    changeAdult: (state, { payload }: PayloadAction<boolean>) => {
      state.adult = payload;
    },
    changeSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.sidebarCollapsed = payload;
    },
    changeRoute: (state, { payload }: PayloadAction<IAirport[]>) => {
      state.route = payload;
    },
    changeCookies: (state, { payload }: PayloadAction<boolean>) => {
      state.cookies = payload;
    }
  }
});

export const { changeCurrency, changeRoute, changeAdult, changeSidebar, changeCookies } =
  utilsSlice.actions;

export const utilsSelector = (state: IAppState): IUtilsState => state.utils;

export default utilsSlice.reducer;
