import { takeEvery, put, call, all } from 'redux-saga/effects';

import { authenticate, authenticateDone, authenticateFail } from './slice';
import { setLoginError } from 'store/alerts/slice';
import { changeCurrency } from 'store/utils/slice';
import APILogin from 'api/login';
import { ILoginPayload } from 'types/user/IUser';

export function* userAuthenticateRequest({ payload }: { payload: ILoginPayload }): any {
  const response = yield call(APILogin.requestLoginToken, payload);
  if (response.status === 200) {
    localStorage.setItem('fnbUserAccessToken', response.data.access_token);
    localStorage.setItem('fnbUserRefreshToken', response.data.refresh_token);
    yield all([
      put(authenticateDone(response.data)),
      put(changeCurrency(response.data.user.currency))
    ]);
  } else {
    yield all([put(authenticateFail()), put(setLoginError(response.response.data.message))]);
  }
}

export default [takeEvery(authenticate, userAuthenticateRequest)];
