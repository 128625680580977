import axios from 'axios';

import config from 'config/index';

const requestLoginToken: any = (payload: any) => {
  return axios
    .post(config.apiUrl + config.login, payload)
    .then((response) => response)
    .catch((err) => err);
};

export default { requestLoginToken };
