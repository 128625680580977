import React from 'react';
import { graphql, useStaticQuery, Script } from 'gatsby';
import { Helmet } from 'react-helmet';
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';
import { StylesProvider } from '@mui/styles';
import { Global } from '@emotion/react';

import setupInterceptors from 'config/setupInterceptors';
import globalStyles from 'styles/global';
import 'swiper/css';
import 'swiper/css/bundle';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function TopLayout({ children, theme }) {
  const [loaded, setLoaded] = React.useState(false);

  setupInterceptors();
  const data = useStaticQuery(graphql`
    query Test {
      gitCommit(latest: { eq: true }) {
        hash
      }
    }
  `);

  return (
    <React.Fragment>
      <Script
        id="create-booking"
        src="https://www.booking.com/affiliate/prelanding_sdk"
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <Script id="booking-widget">
          {`
            var BookingAffiliateWidget = new Booking.AffiliateWidget({
              "iframeSettings": {
                "selector": "bookingAffiliateWidget_af6c136e-678c-4d09-89ab-074c6c43035d",
                "responsive": true
              },
              "widgetSettings": {}
            });
          `}
        </Script>
      )}
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="fo-verify" content="744404fe-0479-497a-b2f5-75cf9df4179e" />
        <meta name="hash" content={data.gitCommit.hash + `_${new Date().toISOString()}`} />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8516567518504942"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      <Global styles={globalStyles} />
      <StylesProvider injectFirst>
        <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
      </StylesProvider>
    </React.Fragment>
  );
}
