import axios from 'axios';
import { useLocation } from '@reach/router';

const config = {
  apiUrl: `${process.env.GATSBY_API_URL}`,
  airports: '/api/airports/',
  articles: '/api/blog/articles/',
  blog: '/api/blog/',
  blogCategories: '/api/blog/categories/',
  brands: '/api/brands/',
  categories: '/api/categories/',
  subcategories: '/api/subcategories/',
  currency: '/api/currency/set/',
  currencies: '/api/currencies/',
  homepage: '/api/products/mainpage/',
  location: '/api/location/set/',
  products: '/api/products/',
  thematic: '/api/thematic-sections/',
  registration: '/auth/registration/',
  login: '/auth/login/',
  refresh: '/auth/token/refresh/',
  delete: '/auth/remove/',
  passwordChange: '/auth/password/change/',
  newsletter: {
    subscribe: '/api/newsletter/subscribe/',
    unsubscribe: '/api/newsletter/unsubscribe/'
  },
  wishlist: '/api/wishlist/'
};

export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const encode = (data: any): FormData => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

export const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: { 'Content-Type': 'application/json' }
});

export default config;
