import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { load, save } from 'redux-localstorage-simple';

import rootReducer from './reducers';
import { rootSaga } from './rootSagas';

const sagaMiddleware = createSagaMiddleware();

const getLoadedState = (preloadedState: any) => {
  if (typeof window !== 'undefined')
    return {
      ...preloadedState,
      ...load({ states: ['fnbUser', 'utils'], disableWarnings: true })
    };

  return {
    ...preloadedState
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const store = (preloadedState) => {
  const str = configureStore({
    reducer: rootReducer,
    preloadedState: getLoadedState(preloadedState),
    middleware: [
      ...getDefaultMiddleware({ thunk: false }),
      sagaMiddleware,
      save({ states: ['fnbUser', 'utils'] })
    ],
    devTools: process.env.NODE_ENV === 'development'
  });

  sagaMiddleware.run(rootSaga);
  return str;
};

export default store;
