import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMainState } from 'types/main/IMain';
import { IAppState } from 'types/state/IAppState';

export const initialState: IMainState = {
  location: null,
  age_gate: false,
  loading: false
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    changeLocation: (state, { payload }: PayloadAction<any>) => {
      state.location = payload;
    },
    changeGate: (state, { payload }: PayloadAction<boolean>) => {
      state.age_gate = payload;
    },
    changeLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    }
  }
});

export const { changeLocation, changeGate, changeLoading } = mainSlice.actions;

export const mainSelector = (state: IAppState): IMainState => state.main;

export default mainSlice.reducer;
