import { combineReducers } from 'redux';

import fnbUser from './user/slice';
import alerts from './alerts/slice';
import utils from './utils/slice';
import main from './main/slice';

const reducers = combineReducers({
  fnbUser,
  alerts,
  utils,
  main
});

export default reducers;
