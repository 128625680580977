import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import { IAlert, IAlertsState } from 'types/alerts/IAlerts';
import { IAppState } from 'types/state/IAppState';

export const initialState: IAlertsState = {
  alerts: [],
  loginError: null
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, { payload }: PayloadAction<IAlert>) => {
      const { message, severity, icon } = payload;
      if (
        !state.alerts.find(
          (currentAlert: IAlert) =>
            message === currentAlert.message && severity === currentAlert.severity
        )
      ) {
        state.alerts = [...state.alerts, { key: nanoid(), message, severity, icon }];
      }
    },
    popAlert: (state) => {
      const newAlerts = [...state.alerts];
      newAlerts.shift();
      state.alerts = newAlerts;
    },
    setLoginError: (state, { payload }: PayloadAction<string>) => {
      state.loginError = payload;
    }
  }
});

export const { addAlert, popAlert, setLoginError } = alertsSlice.actions;

export const alertsSelector = (state: IAppState): IAlertsState => state.alerts;

export default alertsSlice.reducer;
